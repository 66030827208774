/* eslint-disable no-nested-ternary */
import { pdf } from "@react-pdf/renderer";
import { format, getWeek, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-day-picker";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { FotoUser, Icons, IlustracaoTelaVaziaHomem, theme } from "../../assets";
import {
    BarChartDashboard,
    DoughnutChartDashboard,
    ModalPDF,
    PopoverRadix,
    Tooltip,
    CollaboratorResume,
    Pagination,
    ReportStatus,
    LoadingGray,
    SecondaryButton,
    SelectInput,
    PopoverProjectList,
} from "../../components";
import { ManageReportsOptions } from "../../components/molecules/options/reports-options/manage-reports-options";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import {
    TColumnData,
    TDoughnutData,
    TReportProject,
    TReport,
    TReports,
    TTasks,
    TOption,
} from "../../models";
import { timeReports, track } from "../../services";
import {
    dayFromWeek,
    formatISOToMonthYear,
    minutesToStringDate,
    monthFromYear,
} from "../../utils";
import * as s from "./styled-report-manage-review";

type TStatus = "revisar" | "aberto" | "concluido" | "todos";

type TSaveChoices = {
    value: string;
    page: number;
};

export const ReportManage = () => {
    const navigate = useNavigate();
    const { userProjects } = useUser();

    const { value: periodChoice, page: pageChoice } = JSON.parse(
        sessionStorage.getItem("review-choices") || "[]"
    );

    const [isOpenPDF, setIsOpenPDF] = useState(false);

    const [projects, setProjects] = useState<TReportProject>([]);
    const [reports, setReports] = useState<TReports>();
    const [page, setPage] = useState<number>(pageChoice || 1);
    const [total, setTotal] = useState<number>(0);
    const [selected, setSelected] = useState<TReport>();
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [status, setStatus] = useState<TStatus>("todos");
    const [loading, setLoading] = useState(true);
    const [monthsAvailable, setMonthsAvailable] = useState<TOption[]>([]);
    const [choiceDate, setChoiceDate] = useState<Date>(
        periodChoice ? new Date(periodChoice) : new Date()
    );
    const [isDownloading, setIsDownloading] = useState(false);
    const [barChartData, setBarChartData] = useState<TColumnData>();
    const [doughnutChartData, setDoughnutChartData] = useState<TDoughnutData>();
    const [barChartImg, setBarChartImg] = useState<string>();
    const [doughnutChartImg, setDoughnutChartImg] = useState<string>();
    const [dateFilter] = useState<Date>(new Date());
    const { refreshProject } = useUser();

    const firstSearch = useRef(true);

    const getEntries = useFetch({
        fn: track.getEntries,
        start: false,
    });

    const getReports = useFetch({
        fn: timeReports.getReports,
        params: {
            page: 1,
            all: true,
            length: 15,
            users: true,
            projects: true,
            monthlyReports: dateFilter.toISOString(),
            responsible: "reviewer",
        },
        noError: true,
        start: false,
    });

    useEffect(() => {
        refreshProject({
            sectors: true,
            showOnlyActiveProjects: true,
        });
    }, []);

    const getReportService = ({
        page,
        status,
        date,
    }: {
        status?: string;
        page?: number;
        date?: Date;
    }) => {
        let locked: boolean | undefined;
        let reviewed: boolean | undefined;
        if (status === "revisar") {
            locked = true;
            reviewed = false;
            setStatus("revisar");
        }
        if (status === "aberto") {
            locked = false;
            setStatus("aberto");
        }
        if (status === "concluido") {
            reviewed = true;
            setStatus("concluido");
        }
        if (status === "todos") {
            setStatus("todos");
        }
        setPage(page || 1);

        const params: {
            page: number;
            all: boolean;
            length: number;
            monthlyReports: string;
            users: boolean;
            projects: boolean;
            responsible: string;
            reportBilling: boolean;
            filter?: string;
            reviewed?: boolean;
            locked?: boolean;
            projectId?: string;
        } = {
            page: page || 1,
            all: true,
            length: 15,
            users: true,
            projects: true,
            responsible: "reviewer",
            reportBilling: true,
            monthlyReports: date?.toISOString() || choiceDate.toISOString(),
            filter: searchValue,
            reviewed,
            locked,
            projectId: userProjects?.map((project) => project.id_e)?.join(", "),
        };

        if (status === "todos") {
            delete params.reviewed;
            delete params.locked;
            setStatus("todos");
        }

        if (status === "aberto") {
            delete params.reviewed;
        }

        if (status === "concluido") {
            delete params.locked;
        }

        if (!searchValue) {
            delete params.filter;
        }

        getReports.onRefresh(params);
    };

    const getReportsByPage = (page?: number) => {
        if (page) {
            setPage(page);
            getReportService({ page, status });
        } else getReports.onRefresh();
    };

    const getPeriod = (
        period: DateRange | undefined,
        reportId: string | undefined
    ) => {
        getEntries.onRefresh({
            startDate: period?.from?.toString(),
            endDate: period?.to?.toString(),
            responseFormat: "summaryReport",
            page: 0,
            reportId,
        });
    };

    const renderProjects = (
        projects?: { name: string; color: string; id_e: string }[]
    ) => {
        const filteredProjects = projects?.filter(
            (project, index, self) =>
                index === self.findIndex((t) => t.name === project.name)
        );

        return (
            <PopoverProjectList
                projects={filteredProjects}
                message={"Relatório sem projetos."}
            />
        );
    };

    async function generatesDocument(
        barChartImg: string,
        doughnutChartImg: string
    ) {
        try {
            if (selected) {
                const doc = (
                    <CollaboratorResume
                        pdfData={{
                            barChartImg,
                            doughnutChartImg,
                            projects,
                        }}
                        name={selected?.User?.name}
                        period={`${format(
                            parseISO(selected.start_at.slice(0, -1)),
                            "dd/MM/yyyy"
                        )} - ${format(
                            parseISO(selected.end_at.slice(0, -1)),
                            "dd/MM/yyyy"
                        )}`}
                        totalTime={minutesToStringDate(selected.total_time)}
                    />
                );

                const asPdf = pdf();
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                const fileURL = window.URL.createObjectURL(blob);
                const tempLink = document.createElement("a");
                tempLink.href = fileURL;
                tempLink.setAttribute("download", "Report.pdf");
                tempLink.click();
                toast.success("Relatório baixado com sucesso!");
            }
        } catch (error) {
            toast.error("Erro ao fazer download relatório!");
        }
    }

    const getAvailableMonthsForPeriod = (availableMonths: string[]) => {
        return availableMonths?.map((month) => {
            return {
                value: month,
                label: formatISOToMonthYear(month),
            };
        });
    };

    const correctUserName = (name: string) => {
        const nameArray = name.split(" ");
        const firstName = nameArray[0];
        delete nameArray[0];

        return (
            <s.Name>
                <div>{firstName}</div>
                {nameArray.length > 1 && (
                    <span>{nameArray.map((name) => `${name} `)}</span>
                )}
            </s.Name>
        );
    };

    const formatChartsData = (entries: {
        response1: { date: string; total_time: number }[];
        response2: TReportProject;
    }) => {
        const barDates: string[] = [];
        const barTimes: number[] = [];
        const barPeriods: string[] = [];
        const barAbreviatedDates: string[] = [];
        const doughnutLabels: string[] = [];
        const doughnutColors: string[] = [];
        const doughnutTimes: number[] = [];
        let doughnutMaxTime = 0;

        entries?.response1?.sort((a: { date: string }, b: { date: string }) => {
            return a.date.localeCompare(b.date);
        });

        entries?.response1?.forEach(
            (entry: { date: string; total_time: number }) => {
                if (entry.date.length > 10) {
                    const tempDate = entry.date.split(" - ");
                    barAbreviatedDates.push(
                        `${format(parseISO(tempDate[0]), "dd/MM")} - ${format(
                            parseISO(tempDate[1]),
                            "dd/MM"
                        )}`
                    );
                    const tempPeriod = getWeek(
                        parseISO(tempDate[0])
                    ).toString();
                    barPeriods.push(`Sem. ${tempPeriod}`);
                } else if (entry.date.length < 8) {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "yyyy")
                    );
                    barPeriods.push(monthFromYear(parseISO(entry.date), true));
                } else {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "dd/MM")
                    );
                    barPeriods.push(dayFromWeek(parseISO(entry.date), true));
                }
                barDates.push(entry.date);
                barTimes.push(entry.total_time);
            }
        );

        entries?.response2?.forEach(
            (activity: {
                project_name: string;
                project_color: string;
                project_percent: string;
                project_time: number;
                project_entries: TTasks;
            }) => {
                doughnutLabels.push(activity.project_name);
                doughnutColors.push(activity.project_color);
                doughnutTimes.push(activity.project_time);
                doughnutMaxTime += activity.project_time;
            }
        );

        if (Object.keys(entries?.response2).length === 0) {
            setProjects([]);
        } else setProjects(entries?.response2);

        setBarChartData({
            dates: barDates,
            times: barTimes,
            periods: barPeriods,
            abreviatedDates: barAbreviatedDates,
        });
        setDoughnutChartData({
            labels: doughnutLabels,
            colors: doughnutColors,
            times: doughnutTimes,
            maxTime: doughnutMaxTime,
        });
    };

    const saveChoices = ({ page, value }: TSaveChoices) => {
        sessionStorage.setItem(
            "review-choices",
            JSON.stringify({ value, page })
        );
    };

    useEffect(() => {
        const barChartDashboard = document.getElementById(
            "bar-chart-dashboard"
        ) as HTMLCanvasElement;
        const doughnutChartDashboard = document.getElementById(
            "doughnut-chart-dashboard"
        ) as HTMLCanvasElement;

        const tempBarChartImg = barChartDashboard?.toDataURL("image/png");
        const tempDoughnutChartImg =
            doughnutChartDashboard?.toDataURL("image/png");

        setBarChartImg(tempBarChartImg);
        setDoughnutChartImg(tempDoughnutChartImg);

        if (tempBarChartImg && tempDoughnutChartImg && isDownloading) {
            generatesDocument(tempBarChartImg, tempDoughnutChartImg);
            setIsDownloading(false);
        }
    }, [barChartData, doughnutChartData, isDownloading]);

    useEffect(() => {
        if (getEntries.response) {
            const { entries } = getEntries.response;
            formatChartsData(entries);
        }
        if (getReports.response) {
            setReports(getReports.response.reports);
            setTotal(getReports.response.total_pages);
            setMonthsAvailable(
                getAvailableMonthsForPeriod(getReports.response.all_months)
            );
            setLoading(false);
        }
    }, [getEntries.response, getReports.response]);

    useEffect(() => {
        const delay = setTimeout(() => {
            getReportService({ status });
        }, 400);

        if (searchValue.length > 1) {
            return () => {
                clearTimeout(delay);
                setPage(1);
                firstSearch.current = false;
            };
        }

        if (searchValue.length === 0 && firstSearch.current === false) {
            return () => {
                clearTimeout(delay);
                setPage(1);
                firstSearch.current = false;
            };
        }
        return clearTimeout(delay);
    }, [searchValue]);

    useEffect(() => {
        if (choiceDate) {
            getReportService({
                date: choiceDate,
                status,
                page,
            });
        }
    }, [choiceDate]);

    return (
        <s.Container onClick={() => tooltipOpen && setTooltipOpen(false)}>
            {isOpenPDF && (
                <ModalPDF
                    setOpen={setIsOpenPDF}
                    pdfData={{
                        barChartImg,
                        doughnutChartImg,
                    }}
                />
            )}

            {barChartData && doughnutChartData && isDownloading && (
                <>
                    <div
                        style={{
                            position: "absolute",
                            right: -2000,
                            top: -2000,
                            width: "2180px",
                        }}
                    >
                        <BarChartDashboard
                            times={barChartData?.times}
                            periods={barChartData?.periods}
                            abreviatedDates={barChartData?.abreviatedDates}
                        />
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            right: -2000,
                            top: -2000,
                            width: "200px",
                            height: "200px",
                        }}
                    >
                        <DoughnutChartDashboard
                            labels={doughnutChartData?.labels}
                            times={doughnutChartData?.times}
                            colors={doughnutChartData?.colors}
                        />
                    </div>
                </>
            )}
            <ManageReportsOptions />
            {getReports && (
                <s.HeaderContainer>
                    <s.Tabs>
                        <div id="status">
                            <s.Tab
                                open={status === "aberto"}
                                onClick={() => {
                                    setPage(1);
                                    getReportService({
                                        status: "aberto",
                                    });
                                }}
                            >
                                <s.TabTitle>ABERTO</s.TabTitle>
                                <p>
                                    {getReports?.response?.statistic_reports
                                        ?.open_reports || 0}
                                </p>
                            </s.Tab>

                            <s.Tab
                                open={status === "revisar"}
                                onClick={() => {
                                    setPage(1);
                                    getReportService({
                                        status: "revisar",
                                    });
                                }}
                            >
                                <s.TabTitle>REVISAR</s.TabTitle>
                                <p>
                                    {getReports?.response?.statistic_reports
                                        ?.locked_reports || 0}
                                </p>
                            </s.Tab>

                            <s.Tab
                                open={status === "concluido"}
                                onClick={() => {
                                    setPage(1);
                                    getReportService({
                                        status: "concluido",
                                    });
                                }}
                            >
                                <s.TabTitle>CONCLUÍDO</s.TabTitle>
                                <p>
                                    {getReports?.response?.statistic_reports
                                        ?.reviewed_reports || 0}
                                </p>
                            </s.Tab>

                            <s.Tab
                                open={status === "todos"}
                                onClick={() => {
                                    setPage(1);
                                    getReportService({
                                        status: "todos",
                                    });
                                }}
                            >
                                <s.TabTitle>TODOS</s.TabTitle>
                                <p>
                                    {getReports?.response?.statistic_reports
                                        .total_reports || 0}
                                </p>
                            </s.Tab>
                        </div>
                        <s.InputDiv>
                            <s.SearchBar
                                mask=""
                                type="text"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                value={searchValue}
                                icon={<Icons.SearchOutline />}
                                placeholder="Pesquise por profissional, atuação ou projeto"
                                disabled={loading}
                            />
                        </s.InputDiv>
                    </s.Tabs>
                    <s.Period>
                        <s.PeriodTitle>Período: </s.PeriodTitle>
                        <SelectInput
                            options={monthsAvailable}
                            onChange={(e: TOption) => {
                                setChoiceDate(new Date(e.value));
                                saveChoices({
                                    page,
                                    value: e.value,
                                });
                            }}
                            value={{
                                value: formatISOToMonthYear(
                                    choiceDate.toISOString()
                                ),
                                label: formatISOToMonthYear(
                                    choiceDate.toISOString()
                                ),
                            }}
                            icon={<Icons.Chevron color={theme.gray800} />}
                            noForm
                        ></SelectInput>
                    </s.Period>
                </s.HeaderContainer>
            )}

            {getEntries.loading || getReports.loading || loading ? (
                <LoadingGray />
            ) : (
                <s.History>
                    <div className="table-header">
                        <div className="table-row-header">
                            <s.HeaderText>Nome</s.HeaderText>

                            <s.HeaderText>Atuação</s.HeaderText>

                            <s.HeaderText>Total Horas</s.HeaderText>

                            <s.HeaderText>Projetos</s.HeaderText>

                            <Tooltip
                                side="bottom"
                                align={"start"}
                                positionArrow={43}
                                open={tooltipOpen}
                                content={
                                    <s.TooltipContent>
                                        <SecondaryButton
                                            onClick={() => {
                                                setPage(1);
                                                getReportService({
                                                    status: "concluido",
                                                });
                                            }}
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                border: 0,
                                                flexWrap: "nowrap",
                                                padding: "0 8px",
                                                textTransform: "none",
                                                maxWidth: "10rem",
                                                fontSize: "0.875rem",
                                                color: theme.gray600,
                                            }}
                                        >
                                            Concluído
                                        </SecondaryButton>
                                        <SecondaryButton
                                            onClick={() => {
                                                setPage(1);
                                                getReportService({
                                                    status: "revisar",
                                                });
                                            }}
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                border: 0,
                                                flexWrap: "nowrap",
                                                padding: "0 8px",
                                                textTransform: "none",
                                                maxWidth: "10rem",
                                                fontSize: "0.875rem",
                                                color: theme.gray600,
                                            }}
                                        >
                                            Revisar relatório
                                        </SecondaryButton>
                                        <SecondaryButton
                                            onClick={() => {
                                                setPage(1);
                                                getReportService({
                                                    status: "aberto",
                                                });
                                            }}
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                border: 0,
                                                flexWrap: "nowrap",
                                                padding: "0 8px",
                                                textTransform: "none",
                                                maxWidth: "10rem",
                                                fontSize: "0.875rem",
                                                color: theme.gray600,
                                            }}
                                        >
                                            Relatório aberto
                                        </SecondaryButton>
                                        <SecondaryButton
                                            onClick={() => {
                                                setPage(1);
                                                getReportService({
                                                    status: "todos",
                                                });
                                            }}
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                border: 0,
                                                flexWrap: "nowrap",
                                                padding: "0 8px",
                                                textTransform: "none",
                                                maxWidth: "10rem",
                                                fontSize: "0.875rem",
                                                color: theme.gray600,
                                            }}
                                        >
                                            Todos
                                        </SecondaryButton>
                                    </s.TooltipContent>
                                }
                            >
                                <s.HeaderText click>
                                    Status
                                    <Icons.Chevron
                                        onClick={() => {
                                            if (tooltipOpen) {
                                                setTooltipOpen(false);
                                            } else {
                                                setTooltipOpen(true);
                                            }
                                        }}
                                    />
                                </s.HeaderText>
                            </Tooltip>

                            <s.HeaderText>Ver relatório</s.HeaderText>
                        </div>
                    </div>
                    {reports && reports?.length > 0 ? (
                        <div className="rows">
                            {reports?.map((report, index) => {
                                const sessionStorageReport =
                                    JSON.stringify(report);
                                const name = report?.User?.name
                                    ?.replaceAll(" ", "-")
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .replace(/\p{Diacritic}/gu, "");

                                return (
                                    <div className="table-row" key={index}>
                                        <span className="body-user">
                                            <img
                                                className="photo"
                                                src={
                                                    report?.User?.avatar ||
                                                    FotoUser
                                                }
                                                alt={""}
                                            />
                                            <div>
                                                {report?.User?.name
                                                    ? correctUserName(
                                                          report?.User?.name
                                                      )
                                                    : "-"}
                                            </div>
                                        </span>

                                        <span className="body-text">
                                            {report?.User?.Role?.name}
                                        </span>

                                        <span className="body-text">
                                            {report?.total_time
                                                ? minutesToStringDate(
                                                      report?.total_time
                                                  )
                                                : "00:00"}
                                        </span>

                                        <span className="body-text">
                                            <Tooltip
                                                side="bottom"
                                                content={renderProjects(
                                                    report.projects
                                                )}
                                            >
                                                <span className="project">
                                                    <s.ButtonTable>
                                                        <Icons.Tag
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    </s.ButtonTable>
                                                </span>
                                            </Tooltip>
                                        </span>

                                        <ReportStatus
                                            data={report}
                                            page="faturar"
                                        />

                                        <div className="buttons">
                                            <span
                                                className="body-button"
                                                onClick={() => {
                                                    sessionStorage.setItem(
                                                        `${name}`,
                                                        sessionStorageReport
                                                    );
                                                    navigate(
                                                        `/relatorio/gerenciar/revisar/${name}`
                                                    );
                                                }}
                                            >
                                                <Icons.Eye title="Ver relatório" />
                                            </span>
                                            <PopoverRadix
                                                side="bottom"
                                                sideOffset={5}
                                                alignOffset={5}
                                                align="end"
                                                trigger={
                                                    <span className="options">
                                                        <Icons.More />
                                                    </span>
                                                }
                                            >
                                                <s.ContainerPopover>
                                                    <s.ButtonPopover
                                                        onClick={() => {
                                                            setIsDownloading(
                                                                true
                                                            );
                                                            getPeriod(
                                                                {
                                                                    from: new Date(
                                                                        report.start_at.slice(
                                                                            0,
                                                                            -1
                                                                        )
                                                                    ),
                                                                    to: new Date(
                                                                        report.end_at.slice(
                                                                            0,
                                                                            -1
                                                                        )
                                                                    ),
                                                                },
                                                                report?.id_e
                                                            );
                                                            setSelected(report);
                                                        }}
                                                        disabled={
                                                            getEntries.loading
                                                        }
                                                    >
                                                        <Icons.Download />
                                                        <span className="button-text">
                                                            Baixar
                                                        </span>
                                                    </s.ButtonPopover>
                                                </s.ContainerPopover>
                                            </PopoverRadix>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <s.NoContent>
                            <IlustracaoTelaVaziaHomem />
                            <h2>Não há relatórios a serem mostrados</h2>
                            <p>
                                Filtre por outro status ou clique nas opções
                                existentes acima do campo de pesquisa
                            </p>
                        </s.NoContent>
                    )}
                </s.History>
            )}

            {total > 1 && (
                <Pagination
                    id="footer-pagination"
                    page={page - 1}
                    totalPages={total}
                    disabled={getReports.loading}
                    onClick={(page: { selected: number }) => {
                        getReportsByPage(page.selected + 1);
                        saveChoices({
                            page: page.selected + 1,
                            value: periodChoice,
                        });
                    }}
                />
            )}
        </s.Container>
    );
};
