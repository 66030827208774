import { useState } from "react";

import { Icons, IlustracaoTelaVazia, theme } from "../../../assets";
import {
    AlertAction,
    LoadingGray,
    ModalInviteSingle,
    Pagination,
    PopoverProjectList,
    PopoverRadix,
    PopoverSectorList,
    Tooltip,
} from "../../../components";
import { TUseFetch } from "../../../types/TUseFetch";
import { formatToBRMoneyWithoutMoneySign } from "../../../utils";
import { TInvite, TInviteData } from "../collaborators-invite";
import * as s from "../styled-collaborators-invite";

type TTabInvite = {
    getInvites: TUseFetch;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    inviteData?: TInviteData;
    filteredInvites?: TInvite[];
    resendInvite: (inviteIds: string[]) => void;
    cancelInvite: (inviteId: string) => void;
    deleteInvite: (inviteIds: string[]) => void;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    getInvitesByPage: (page?: number) => void;
};

export const TabInvites = ({
    getInvites,
    loading,
    inviteData,
    filteredInvites,
    resendInvite,
    cancelInvite,
    deleteInvite,
    page,
    getInvitesByPage,
}: TTabInvite) => {
    const [openSingleInvite, setOpenSingleInvite] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState<TInvite>();

    const renderInviteStatus = (status: string) => {
        if (status === "waiting") return "Aguardando";
        if (status === "accepted") return "Cadastrado";
        if (status === "canceled") return "Cancelado";
        return "Expirado";
    };

    const renderInvites = () => {
        if (!loading && filteredInvites && filteredInvites.length > 0) {
            return filteredInvites?.map((invite: TInvite, index: number) => (
                <div className="content-row" key={index}>
                    <div>{invite.name}</div>
                    <div>{invite.email}</div>
                    <div>{invite.contract}</div>
                    <div>
                        {invite.hourValue === "0"
                            ? `0,00`
                            : formatToBRMoneyWithoutMoneySign(
                                  Number(invite.hourValue)
                              )}
                    </div>
                    <div>{invite.role}</div>
                    <div>
                        <Tooltip
                            side="bottom"
                            disabled={!invite.sectors.length}
                            content={
                                <PopoverSectorList
                                    sectors={invite?.sectors.map(
                                        (sector) => sector?.name
                                    )}
                                />
                            }
                        >
                            <s.Pill disabled={!invite.sectors.length}>
                                <p>
                                    <Icons.Template />
                                </p>
                            </s.Pill>
                        </Tooltip>
                    </div>
                    <Tooltip
                        side="bottom"
                        disabled={!invite.projects.length}
                        content={
                            <PopoverProjectList
                                projects={invite?.projects.map((project) => ({
                                    name: project?.name || "",
                                    color: project?.color || "",
                                }))}
                            />
                        }
                    >
                        <s.Pill disabled={!invite.projects.length}>
                            <p>
                                <Icons.Tag />
                            </p>
                        </s.Pill>
                    </Tooltip>

                    <div className="center">
                        <s.Status $status={invite.inviteStatus}>
                            {renderInviteStatus(invite.inviteStatus)}
                        </s.Status>
                    </div>

                    <PopoverRadix
                        side="bottom"
                        sideOffset={5}
                        align="end"
                        trigger={
                            <span className="trigger">
                                <Icons.More />
                            </span>
                        }
                    >
                        <s.ContainerPopover>
                            <s.ButtonPopover
                                onClick={() => resendInvite([invite.id_e])}
                            >
                                <Icons.EmailForward />
                                <span className="button-text">
                                    Reenviar convite
                                </span>
                            </s.ButtonPopover>

                            <s.ButtonPopover
                                onClick={() => {
                                    setSelectedInvite(invite);
                                    setOpenSingleInvite(true);
                                }}
                            >
                                <Icons.PencilOutline />
                                <span className="button-text">
                                    Editar dados
                                </span>
                            </s.ButtonPopover>

                            {invite.inviteStatus === "waiting" && (
                                <s.ButtonPopover
                                    onClick={() => {
                                        cancelInvite(invite.id_e);
                                    }}
                                >
                                    <Icons.XOutline color={theme.gray600} />
                                    <span className="button-text">
                                        Cancelar convite
                                    </span>
                                </s.ButtonPopover>
                            )}

                            <AlertAction
                                onClickBtn={() => deleteInvite([invite.id_e])}
                                trigger={
                                    <s.ButtonPopover>
                                        <Icons.Trash />
                                        <span className="button-text">
                                            Deletar
                                        </span>
                                    </s.ButtonPopover>
                                }
                                description={`Você deseja deletar o convite selecionado?`}
                                title={"Deletar convite"}
                                textBtn={"Deletar"}
                            />
                        </s.ContainerPopover>
                    </PopoverRadix>
                </div>
            ));
        }

        if (!loading && filteredInvites?.length === 0) {
            return (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Não existem convites enviados</h2>
                    <p>
                        Clique em <strong>adicionar</strong> para convidar
                        profissionais
                    </p>
                </s.NoContent>
            );
        }

        return <LoadingGray />;
    };

    return (
        <>
            {openSingleInvite && (
                <ModalInviteSingle
                    setOpen={setOpenSingleInvite}
                    getInvites={getInvites}
                    inviteData={selectedInvite}
                    editInvite
                />
            )}
            <s.TabContent>
                <div>
                    <div className="invites-title">
                        <div>Nome</div>
                        <div>E-mail</div>
                        <div>
                            Formato de
                            <br />
                            contratação
                        </div>
                        <div>Valor R$</div>
                        <div>Atuação</div>
                        <div className="center">Setor</div>
                        <div className="center">Projeto</div>
                        <div className="center">Status</div>
                    </div>
                    <div className="invites-content">{renderInvites()}</div>
                </div>
                <div className="pagination">
                    <Pagination
                        id="footer-pagination"
                        page={page - 1}
                        totalPages={inviteData?.total_pages || 1}
                        disabled={getInvites.loading}
                        onClick={(page: { selected: number }) => {
                            getInvitesByPage(page.selected + 1);
                        }}
                    />
                </div>
            </s.TabContent>
        </>
    );
};
