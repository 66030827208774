import styled from "styled-components";

import { Input } from "../../components";

type TButtonPopover = { disabled?: boolean };

type TTooltipContent = { simple?: boolean };

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;
`;

export const Tab = styled.span<{ open?: boolean }>`
    cursor: pointer;
    ${({ open, theme }) =>
        open
            ? `color: ${theme.purple600} !important;`
            : `color: ${theme.gray400} !important;`}
`;

export const TabTitle = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    text-align: left;
`;

export const SearchBar = styled(Input)`
    padding: 13px 24px !important;
    height: 50px !important;
    background: var(--color-neutral-white-02, #fcfcfc) !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    color: var(--color-gray-400, #9e9ba7);
`;

export const HeaderContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    display: flex;
    width: 100%;
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 1rem;
    border-radius: 4px;
    width: 100%;
    div#status {
        display: flex;
        gap: 2.5rem;
        flex-direction: row;
        span:hover {
            cursor: pointer;
        }
    }
`;

export const Period = styled.div`
    display: flex;
    width: 100%;
    margin-top: 24px;
    gap: 14px;
    padding-right: 19px;
`;

export const PeriodTitle = styled.div`
    color: var(--color-gray-400, #9e9ba7);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    margin-top: 8px;
`;

export const Name = styled.div`
    color: var(--color-gray-600, #6e697a);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    span {
        font-size: 12px;
    }
`;

export const HeaderText = styled.div<{
    click?: boolean;
    orderBy?: "asc" | "desc";
}>`
    color: var(--color-gray-800, #3e374e);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    text-align: left;
    display: flex;
    gap: 8px;
    align-items: center;

    ${({ click }) => click && `cursor: pointer;`}

    svg {
        transform: rotate(
            ${({ orderBy }) => (orderBy === "asc" ? "180deg" : "0deg")}
        );
    }
`;

export const History = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    overflow: hidden;

    span.no-reports {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        color: ${({ theme }) => theme.gray500};
        border-top: 1px solid ${({ theme }) => theme.gray200};
    }

    div.table-row {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr 1fr;
        align-items: center;
        height: 3rem;
        padding-left: 24px;

        &:hover {
            background: ${({ theme }) => theme.gray50};
        }
    }
    div.table-row-header {
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr 1fr;
        align-items: center;
        height: 3rem;
        margin-left: 24px;
        height: 48px;
    }

    div.table-header {
        background: ${({ theme }) => theme.purple50};
        border-radius: 4px 4px 0 0;

        span.heading-text {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            font-size: 1rem;
            font-weight: 500;
            color: ${({ theme }) => theme.purple800};
        }
    }

    div.rows {
        div.table-row {
            height: 3.75rem;
            border-top: 1px solid ${({ theme }) => theme.gray200};

            span.body-user {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                gap: 1rem;
                font-size: 0.875rem;
                color: ${({ theme }) => theme.gray600};

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre-wrap;
                }

                img.photo {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            span.body-text {
                /* place-items: center; */
                font-size: 0.875rem;
                color: ${({ theme }) => theme.gray600};
            }

            div.buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                span.body-button {
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 0.875rem;
                    height: 2rem;
                    width: 2rem;
                    color: ${({ theme }) => theme.white};
                    border: ${({ theme }) => `1px solid ${theme.gray100}`};
                    border-radius: 0.25rem;
                }

                span.options {
                    display: flex;
                    opacity: 0.2;
                    padding: 0.75rem 1.875rem;
                    align-items: center;
                    justify-content: center;
                }
            }

            &:hover {
                background: ${({ theme }) => theme.gray50};

                div.buttons {
                    span.options {
                        opacity: 1;
                    }
                }
            }
        }
    }
`;

export const TooltipContent = styled.div<TTooltipContent>`
    display: flex;
    flex-direction: column;
    width: auto;

    span.tooltip-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 600;
    }

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    div.tooltip-row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        div.icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 2rem;
            height: 32px;
            border: 1px solid ${({ theme }) => theme.gray100};
            border-radius: 4px;

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    padding: 1.5rem 2rem;
    gap: 1rem;
    overflow: hidden;

    div.status {
        display: flex;
        flex-direction: row;
        gap: 2.5rem;
    }

    span {
        font-size: 1rem;
        color: ${({ theme }) => theme.gray400};
    }
`;

export const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StatusDiv = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    span {
        font-size: 1rem;
        color: ${({ theme }) => theme.gray400};
    }
`;

export const ContainerPopover = styled.div`
    background: ${({ theme }) => theme.white};
    padding: 0.5rem 0;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    position: relative;
    width: 130px;
`;

export const ButtonPopover = styled.button<TButtonPopover>`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.25rem 20px;
    gap: 0.75rem;
    background: ${({ theme }) => theme.white};
    cursor: pointer;

    svg {
        width: 24px;
        height: 24px;

        path {
            ${({ disabled, theme }) => disabled && `stroke: ${theme.gray200}`}
        }
    }

    span.button-text {
        font-weight: 400;
        font-size: 0.875rem;
        color: ${({ theme, disabled }) =>
            disabled ? theme.gray200 : theme.gray600};
    }

    &:hover {
        background: ${({ theme }) => theme.gray50};
        transition: 0.3s ease-in-out;
    }
`;

export const ButtonTable = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    height: 32px;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover {
        border: 1px solid ${({ theme }) => theme.pink100};
    }

    &:active {
        background: ${({ theme }) => theme.pink50};
    }
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin: 2.625rem 0;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
        max-width: 28.125rem;
        text-align: center;
    }
`;

export const ProjectsList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: flex-start;

    span#title {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
        text-align: start;
    }

    span#empty {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    span {
        width: 100%;
    }
`;

export const TooltipContentStatus = styled.div<TTooltipContent>`
    display: flex;
    flex-direction: column;
    width: auto;

    span.tooltip-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 600;
    }

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    div.tooltip-row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        div.icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 2rem;
            height: 32px;
            border: 1px solid ${({ theme }) => theme.gray100};
            border-radius: 4px;

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
`;
