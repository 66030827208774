import styled from "styled-components";

type TCollaboratorsTable = {
    manager?: boolean;
    onlyView?: boolean;
};

type TTableRow = {
    hasClick?: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;

    .tutorials-popover {
        margin-top: 0px;
    }
`;

export const Details = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.background};
    gap: 2rem;

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    div.buttonAddPeople {
        display: flex;
        flex-direction: row;
        width: 167px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: ${({ theme }) => theme.purple500};
        color: #fff;
        cursor: pointer;
    }

    form#filter {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1.5rem;
        width: 27rem;

        button {
            padding: 1rem;
            width: fit-content;
            white-space: nowrap;
        }

        input {
            width: 100%;
            height: 50px;
        }
    }

    .roleFilters {
        display: flex;
        flex-direction: column;
        align-items: start;
        overflow-y: auto;
        padding: 5px;

        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }
`;

export const LoadingWrapper = styled.div`
    display: flex;
    height: calc(522px + 2.75rem);
`;

export const CollaboratorsTable = styled.table<TCollaboratorsTable>`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    overflow: hidden;

    .selected-row-information.show {
        opacity: 1;
    }

    tr {
        display: grid;
        grid-template-columns: ${({ onlyView }) =>
            onlyView
                ? "6% 17% 12% 17% 16% 13% 17% 10%"
                : "5% 17% 12% 17% 10% 10% 13% 10%"};
        align-items: center;
        justify-content: center;
        height: 50px;
        background-color: ${({ theme }) => theme.purple50};
        border: 0.1px solid #e7e6e9;

        span.heading-text {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.gray800};
            font-weight: 600;
        }
    }

    tbody {
        tr {
            height: 60px;
            background-color: ${({ theme }) => theme.white};
        }

        tr:hover {
            background-color: ${({ theme }) => theme.purple50};
        }
    }
`;

export const TableRow = styled.tr<TTableRow>`
    ${({ hasClick }) => hasClick && `cursor: pointer`};

    td {
        color: ${({ theme }) => theme.gray600};

        img.photo {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            object-fit: cover;
        }

        a.name {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.gray600};
        }

        span {
            font-size: 14px;
            @media (max-width: 1366px) {
                font-size: 12px;
            }
        }
    }

    td:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
    }

    width: 100%;
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #503479;
    font-weight: 600;

    button {
        width: 12.375rem;
        color: #503479;
        font-size: 1rem;
        font-weight: 600;
        white-space: nowrap;
        text-transform: capitalize;
        line-height: 24px;
        letter-spacing: 1.28px;
        padding: 12px 24px;
        height: 48px;
        gap: 8px;
        text-transform: uppercase;
        border-radius: 4px;
        border: 1.2px solid #503479;
    }

    @media (max-width: 1366px) {
        button {
            font-size: 0.875rem;
        }
        button div {
            font-size: 0.875rem;
        }
    }
`;

export const Pagination = styled.div`
    flex: 1;
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.gray600};
`;

export const NoContent = styled.tr`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin: 2.625rem 0;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const TableHeading = styled.th`
    display: flex;
    font-size: 14px;
    color: var(--color-gray-800, #3e374e);
    font-weight: 600;
    letter-spacing: 0.07px;
    gap: 0.7rem;
    align-items: center;
    text-align: left;

    &:last-child {
        padding: 0;
    }

    span.expectedHours {
        width: 50px;
    }

    span.profissionalColumn {
        display: flex;
        flex-direction: row;
        gap: 2.5px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {
            color: ${({ theme }) => theme.gray600};
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            @media (max-width: 1366px) {
                font-size: 10px;
            }
        }
    }

    span.useCase {
        width: 50px;
    }

    span.useCaseText {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;

export const CollaboratorName = styled.td`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-gray-800, #3e374e);
    font-weight: 400;
    letter-spacing: 0.07px;
    gap: 0.8rem;
    width: fit-content;
    a {
        margin-right: 0.8rem;
    }
    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;

export const Status = styled.td<{ $active?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 14px;
    background: ${({ $active }) => ($active ? "#2780270D" : "#F9F9F9")};
    border-radius: 8px;
    color: ${({ $active, theme }) =>
        $active ? theme.green1 : "#D14141"} !important;
    font-weight: 600;
    width: fit-content;
    padding: 2px 20px;

    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;

export const Hours = styled.td`
    display: flex;
    justify-content: space-between;
`;

export const Workload = styled.span`
    display: flex;
    align-items: center;
    text-decoration: underline;
    margin-right: 60px;
    justify-content: space-between;
`;

export const LockHours = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    color: ${({ theme }) => theme.gray600};
    svg {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;

export const Acting = styled.td`
    display: flex;
    align-items: center;

    td {
        padding-left: 0px !important;
        padding-right: 15px;
    }
`;

export const lockedUseCaseToolTip = styled.div`
    display: flex;
    flex-direction: column;
    font-size: "13px";
    padding: "7px";
    color: ${({ theme }) => theme.gray800};
    font-weight: 400;
    width: 210px;
    gap: 10px;
    padding: 12px 16px;

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 5px;
        h1 {
            font-size: 14px;
            font-weight: 600;
            color: ${({ theme }) => theme.gray600};
        }
    }
    p {
        color: ${({ theme }) => theme.gray600};
        font-size: 12px;
        font-weight: 400;
    }
`;

export const SelectedRowInformation = styled.tr`
    background-color: ${({ theme }) => theme.gray50};
    color: ${({ theme }) => theme.gray600};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 63px;
    padding-left: 37px;
`;

export const EditOptions = styled.section`
    display: flex;
    flex-direction: row;
    gap: 24px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        color: ${({ theme }) => theme.purple500};
        cursor: pointer;
        letter-spacing: 1.28px;
        line-height: 24px;

        span {
            text-decoration: underline;
            font-weight: 600;
        }

        @media (max-width: 1366px) {
            font-size: 12px;
        }
    }
`;

export const UseCaseTooltip = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 20px;
    width: 340px;

    div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        strong {
            color: ${({ theme }) => theme.purple500};
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.07px;
        }

        p {
            color: ${({ theme }) => theme.purple500};
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.07px;
        }
    }
`;
